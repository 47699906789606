<template>
  <div>
    <!--begin::Dashboard-->
    
    <div class="row">
      <div class="col-lg-12 col-xxl-12">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark"
                >Listado de validaciones</span
              >
              <span class="text-muted mt-3 font-weight-bold font-size-sm"
                >{{ rows }} Validaciones enviadas desde el marketplace</span
              >
            </h3>
            <div class="card-toolbar">
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-0 pb-3">
            <div>
              <div class="row pl-4">
                <div class="col-9 input-group input-group-lg input-group-solid mb-8 pl-0">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="la la-search"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg inline"
                    name="address1"
                    placeholder="Busqueda"
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    hide-details
                  />
                </div>
                <div class="col-3">
                  <b-form-select
                    v-model="filter.isValidated"
                    :options="filterOptions"
                    class="mb-3 form-control form-control-solid form-control-lg"
                    value-field="item"
                    text-field="name"
                    input-id="input-type"
                    disabled-field="notEnabled"
                    style="height: 44px;"
                  ></b-form-select>
                </div>
              </div>
              <div class="tab-content position-relative">
                <LoaderPartial v-if="showLoader"></LoaderPartial>
                <!--begin::Table-->
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                  >
                    <thead>
                      <tr class="text-left">
                        <th class="pl-7">
                          <span class="text-dark-75"
                            >Documento
                          </span>
                        </th>
                        <th class="hover" @click="sortListFor('documentName')">
                          Nombre
                          <i
                            class="fa fa-angle-up"
                            aria-hidden="true"
                            v-if="sortingList.documentName == 'DESC'"
                          ></i>
                          <i
                            class="fa fa-angle-down"
                            aria-hidden="true"
                            v-if="sortingList.documentName == 'ASC'"
                          ></i>
                        </th>
                        <th class="hover" @click="sortListFor('service')">
                          Servicio
                          <i
                            class="fa fa-angle-up"
                            aria-hidden="true"
                            v-if="sortingList.service == 'DESC'"
                          ></i>
                          <i
                            class="fa fa-angle-down"
                            aria-hidden="true"
                            v-if="sortingList.service == 'ASC'"
                          ></i>
                        </th>
                        <th class="hover" @click="sortListFor('isValidated')">
                          Validado
                          <i
                            class="fa fa-angle-up"
                            aria-hidden="true"
                            v-if="sortingList.isValidated == 'DESC'"
                          ></i>
                          <i
                            class="fa fa-angle-down"
                            aria-hidden="true"
                            v-if="sortingList.isValidated == 'ASC'"
                          ></i>
                        </th>
                        <th class="hover" @click="sortListFor('updatedAt')">
                          Fecha de modificación
                          <i
                            class="fa fa-angle-up"
                            aria-hidden="true"
                            v-if="sortingList.updatedAt == 'DESC'"
                          ></i>
                          <i
                            class="fa fa-angle-down"
                            aria-hidden="true"
                            v-if="sortingList.updatedAt == 'ASC'"
                          ></i>
                        </th>
                        
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item, i) in validations">
                        <tr v-bind:key="i">
                          <td class="pl-0 py-8">
                            <div class="text-center">
                                <a :href="`#/validations/${item.id}`">
                                  <div
                                    class="symbol-label"
                                    style="overflow:hidden;position:relative;"
                                    v-if="item.documentUrl.includes('.pdf')"
                                  >
                                    <object 
                                        :data="item.documentUrl" 
                                        type="application/pdf" 
                                        width="80" 
                                        height="103"
                                        style="position:relative"
                                    >
                                    </object>

                                    <div style="position: absolute; top:0; left:0; width:100%; height:100%; z-index:10;"></div>
                                  </div>
                                  <img v-else :src="item.documentUrl" style="max-width:80px; max-height:103px">
                                </a>
                            </div>
                          </td>
                          <td>
                            {{item.documentName}}
                          </td>
                          <td>{{item.serviceName}}</td>
                          <!-- <td>{{ item.showToAll ? "Todos" : item.coreTenanciesTargets.length}}</td> -->
                          <td>{{item.isValidated ? "Si" : "No"}}</td>
                         
                          <td>
                            <span class="text-muted font-weight-bold d-block">{{
                              new Date(item.updatedAt)
                                .toLocaleString()
                                .split(" ")[0]
                            }}</span>
                          </td>
                          <td>
                            <b-dropdown id="dropdown-1" text="Acciones">
                              <b-dropdown-item :href="`#/validations/${item.id}`"
                                >Detalles</b-dropdown-item
                              >
                              <b-dropdown-item target="_blank" :href="item.documentUrl"
                                >Descargar</b-dropdown-item
                              >
                            </b-dropdown>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <div>
                    <div class="row">
                      <div class="col m-1 ">
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          page-click=""
                          aria-controls="my-table"
                        ></b-pagination>
                      </div>
                      <div class="col m-1 text-right">
                        Elementos por pagina
                        <b-form-select
                          v-model="perPage"
                          :options="optionPerPage"
                          class="mb-3"
                          style="width:70px;"
                          value-field="item"
                          text-field="name"
                          disabled-field="notEnabled"
                        ></b-form-select>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Table-->
              </div>
            </div>
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<style scoped>
table th.hover {
  cursor: pointer;
}
table th.hover:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

import LoaderPartial from "@/view/content/LoaderPartial.vue";

export default {
  name: "request-validations",
  data() {
    return {
      resource: "request-validations",
      search: "",
      validations: [],
      services: [],
      deletableTenant: {},
      deletableTenantName: "",
      currentPage: 1,
      rows: 0,
      perPage: 5,
      optionPerPage: [5, 10, 25, 50, 100],
      sortingList: {
        isValidated: "ASC",
        updatedAt: "DESC",
      },
      filterOptions: [
        { item: null, name: "Mostrar todos" },
        { item: true, name: "Mostrar solo validados" },
        { item: false, name: "Mostrar solo no validados " },
      ],
      filter: {
        isValidated: null
      },
      dialog: false,
      showLoader: true
    };
  },
  components: {
    LoaderPartial
  },
  async mounted() {
    await this.getCurrentUser();
    await this.loadTable();

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Validaciones" }]);
    console.log(this.currentUser, this.currentUser.id);
  },
  watch: {
    search: function() {
      this.loadTable();
    },
    perPage: function() {
      this.loadTable();
    },
    currentPage: function() {
      this.loadTable();
    },
    "filter.isValidated": function() {
      this.currentPage = 1;
      this.loadTable();
    }
  },
  methods: {
    async loadTable(){
      await this.getResourceList();
      await this.getServicesList();

      this.validations = this.validations.map(validation => {
        let service = this.services.filter(service => service.id == validation.service);
        console.log(service);
        validation.serviceName = service[0].name;
        return validation;
      })
    },
    sortListFor(row) {
      if (!this.sortingList[row]) {
        this.sortingList = []; // Clear?
        this.sortingList[row] = "DESC";
      } else if (this.sortingList[row] == "ASC") this.sortingList[row] = "DESC";
      else if (this.sortingList[row] == "DESC") this.sortingList[row] = "ASC";

      this.getResourceList();
    },
    getQuery() {
      let search = this.search.toLowerCase();
      let query = {
        filter: {
          limit: this.perPage,
          skip: this.perPage * (this.currentPage - 1),
          order: Object.keys(this.sortingList).map(
            key => `${key} ${this.sortingList[key]}`
          ),
          where: {
            or: [
              { documentName: { like: `${search}`, options: "i" } },
              { documentUrl: { like: `${search}`, options: "i" } },
            ]
          }
        }
      };
      if(this.filter.isValidated){
        query.filter.where = {
          ...query.filter.where,
          isValidated: this.filter.isValidated
        }
      }
      if (query.filter.order.length === 0) {
        query.filter.order.push("createdAt DESC");
      }
      return query;
    },
    async getCurrentUser() {
      let { data } = await ApiService.get(`admins`, "me");
      console.log("ME", data);
      this.currentUser = data;
    },
    async getResourceCount( retry = 3 ) {
      try {
        let { data } = await ApiService.query(`${this.resource}/count`, {
          where: this.getQuery().filter.where
        });
        this.rows = data.count;
        return data.count;
      } catch (error) {
        if (retry > 0) {
					console.log("(Services) getResourceCount Failed... retrying");
					await setTimeout( async () => {
						await this.getResourceCount(retry-1);
					}, 200);
					return true;
				}
				console.log("(Services) getResourceCount Failed... no more attempts");
				console.log(error);
				throw(error);
      }
    },
    async getResourceList( retry = 3 ) {
      try {
        this.showLoader = true;
        await this.getResourceCount();

        let validations = await ApiService.query(this.resource, this.getQuery());
        console.log(validations);
        let data = validations.data;
        this.validations = data;
        this.showLoader = false;
        console.log(data);
      } catch (error) {
        if (retry > 0) {
					console.log("(Services) getResourceList Failed... retrying");
					await setTimeout( async () => {
						await this.getResourceList(retry-1);
					}, 200);
					return true;
				}
				console.log("(Services) getResourceList Failed... no more attempts");
				console.log(error);
				throw(error);
      }
    },
    async getServicesList() {
      let {data} = await ApiService.query(`ai-products`,{filter: {limit: 1000}});
      this.services = data;
    }
  },
  computed: {
    //...mapGetters(["currentUser"]),
  }
};
</script>
